import "regenerator-runtime";
import csj from "../parser";
import Swiper from "swiper/bundle";
import "swiper/swiper-bundle.css";

(async () => {
  try {
    const host = window.location.origin;
    const resp = await fetchHighlights(host);
    const highlights = resp.split("\n").length === 2 ? [] : csj.csjToJSON(resp);
    const highlightNodes = highlights.map(createHighlightElement);
    document.getElementById("highlights-container").append(...highlightNodes);
  } catch (e) {
    console.error(e);
  }
  applySlides();
})();

function applySlides() {
  var swiper = new Swiper(".my-swipper", {
    spaceBetween: 10,
    slidesPerView: 2,
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    controller: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      //   // when window width is >= 480px
      480: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      // when window width is >= 1024px
      1024: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
    },
  });
}

function createHighlightElement(item) {
  const a = document.createElement("a");
  a.className = "swiper-slide rounded-lg flex justify-center";
  a.href = item.redirect_url;
  a.target = "_blank";
  const image = document.createElement("img");
  image.src = item.image;
  image.className = "w-full rounded-lg object-cover";
  a.appendChild(image);
  return a;
}

async function fetchHighlights(host) {
  const resp = await fetch(`${host}/ads/highlights/`);
  return await resp.text();
}
