function csjToJSON(csjStr) {
  const lines = csjStr.split("\n");
  const keys = lines[0].split(",");
  var result = [];

  for (let i = 1; i < lines.length - 1; i++) {
    const currentline = lines[i];
    const key = JSON.parse(`[${keys}]`);
    const value = JSON.parse(`[${currentline}]`);
    const obj = {};

    key.map(function (e, i) {
      obj[e] = value[i];
    });

    result.push(obj);
  }

  return result;
}

const toCamel = (s) => {
  return s.replace(/([-_][a-z])/ig, ($1) => {
    return $1.toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
};

const isArray = function (a) {
  return Array.isArray(a);
};

const isObject = function (o) {
  return o === Object(o) && !isArray(o) && typeof o !== 'function';
};

const keysToCamel = function (o) {
  if (isObject(o)) {
    const n = {};

    Object.keys(o)
      .forEach((k) => {
        n[toCamel(k)] = keysToCamel(o[k]);
      });

    return n;
  } else if (isArray(o)) {
    return o.map((i) => {
      return keysToCamel(i);
    });
  }

  return o;
};

module.exports = { csjToJSON, keysToCamel };
